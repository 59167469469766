@font-face {
    font-family: "Congenial";
    src: local("Congenial"), url(../fonts/Congenial-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Jameel Noori Nastaleeq";
    src: local("Jameel Noori Nastaleeq"), url(../fonts/Jameel-Noori-Nastaleeq-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Kalpurush";
    src: local("Kalpurush"), url(../fonts/Kalpurush.ttf) format("truetype");
}
